import React from 'react';

import {Box, LayoutGrid, Section, BoxProps} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import styled from 'styled-components/macro';

const {H3} = Typography;

const Wrapper = styled(Section).attrs({})``;

const RolesIframe = styled('iframe')`
  width: 100%;
  height: 100%;
`;

export const Roles: React.FC<BoxProps & {sectionTitle: string}> = ({sectionTitle, ...boxProps}) => {
  return (
    <Wrapper backgroundColor="white" pt={['40px', null, null, '96px']} {...boxProps}>
      <LayoutGrid overflow="hidden">
        <Box mb={['24px', null, null, '0px']} gridRow={'1'} gridColumn={['1/end']}>
          <H3 as="h2" color="darkGreen" mb={['0px', null, null, '50px']}>
            {sectionTitle}
          </H3>
        </Box>
        <Box gridColumn={['1/end']} minHeight={'800px'}>
          <RolesIframe id="ashby_embed_iframe" src="https://jobs.ashbyhq.com/Chief?embed=js" title="Ashby Job Board"></RolesIframe>
          <div id="ashby_embed" />
        </Box>
      </LayoutGrid>

    </Wrapper>
  );
};
